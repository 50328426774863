<script>

export default {
  name: 'contact'
}
</script>

<template>
  <section class="white_content">
    <router-link to="/experience">
      <div class="white_content-1">
        <i class="fas fa-envelope text-xl" ></i>
        <p style="font-size: small">联系我们</p>
      </div>
    </router-link>
  </section>
</template>

<style scoped>
.white_content {
  position: fixed;
  right: 10px;
  bottom: 100px;
  z-index: 999;
  background: none;
}

.white_content-1 {
  background: rgb(1, 162, 128);
  padding: 3px 10px 0 10px;
  border-radius: 10%;
  text-align: center;
  color: #ffff;
}
</style>
