import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HOME",
    component: () => import("../views/index.vue")
  },
  {
    path: "/SCAXLink",
    name: "SCAXLink",
    component: () => import("../views/SCAXLink.vue")
  },
  {
    path: "/SMPM",
    name: "SMPM",
    component: () => import("../views/SMPM.vue")
  },
  {
    path: "/SPDM",
    name: "SPDM",
    component: () => import("../views/SPDM.vue")
  },
  {
    path: "/SPMS",
    name: "SPMS",
    component: () => import("../views/SPMS.vue")
  },
  {
    path: "/experience",
    name: "experience",
    component: () => import("../views/experience.vue")
  },
  {
    path: "/case",
    name: "case",
    component: () => import("../views/case.vue")
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue")
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("../views/partners.vue")
  },
  {
    path: "/S3DViewer",
    name: "S3DViewer",
    component: () => import("../views/S3DViewer.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0 // firefox
  document.documentElement.scrollTop = 0 // safari
  window.pageYOffset = 0 // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
})

export default router;
