<script>
export default {
  name: 'Footer'
}
</script>

<template>
  <div>
    <footer id="footer-main" class="position-relative">
      <div class="footer pt-lg-7 footer-dark bg-dark">
        <!-- SVG shape -->
        <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
          <svg class=" fill-section-secondary" height="100px" preserveAspectRatio="none"
               style="enable-background:new 0 0 2560 100;" viewBox="0 0 2560 100" width="2560px" x="0px"
               xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               y="0px">
                    <polygon points="2560 0 2560 100 0 100"></polygon>
                </svg>
        </div>
        <!-- Footer -->
        <div class="container pt-4">
          <div class="row">
            <div class="col-lg-4 mb-5 mb-lg-0">
              <!-- Theme's logo -->
              <router-link to="/SMPM">
                <img id="footer-logo" alt="Image placeholder" src="@/img/footer-logo.png" style="width: 120px;">
              </router-link>
              <!-- Webpixels' mission -->
              <p class="mt-4 text-sm opacity-8 pr-lg-4">
                深圳市大鹏新区大鹏街道水头社区思创东岸技术园2栋101</p>
              <p>电话:86-755-82666706</p>
              <!-- Social -->


            </div>
            <div class="col-lg-3 col-6 col-sm-4 mb-5 mb-lg-0"></div>
            <div class="col-lg-3 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
              <h6 class="heading mb-3">产品&解决方案</h6>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/">SPLM产品全生命周期管理平台</router-link>
                </li>
                <li>
                  <router-link to="/SPDM">SPDM产品数据管理系统</router-link>
                </li>
                <li>
                  <router-link to="/SPMS">SPMS科研项目管理系统</router-link>
                </li>
                <li>
                  <router-link to="/SMPM">SMPM结构化工艺管理系统</router-link>
                </li>
                <li>
                  <router-link to="/SCAXLink">SpeedDLink设计工具集成软件</router-link>
                </li>
                <li>
                  <router-link to="/S3DViewer">SpeedVue轻量可视化软件</router-link>
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
              <h6 class="heading mb-3">东之林科技</h6>
              <ul class="list-unstyled">
                <li>
                  <router-link to="/case">技术服务</router-link>
                </li>
                <li>
                  <router-link to="/experience">体验中心</router-link>
                </li>
                <li>
                  <router-link to="/partners">合作伙伴</router-link>
                </li>
                <li>
                  <router-link to="/about">关于我们</router-link>
                </li>
              </ul>
            </div>
          </div>
          <hr class="divider divider-fade divider-dark my-4">
          <div class="row align-items-center justify-content-md-between pb-4">
            <div class="col-md-6">
              <div class="copyright text-sm font-weight-bold text-center text-md-left d-inline-flex">
                &copy; 2023
                <router-link class="font-weight-bold" to="/">东之林科技</router-link>
              </div>
              <div class="copyright text-sm font-weight-normal text-center text-md-left d-inline-flex ml-3">
                <a href="https://beian.miit.gov.cn" target="_blank" style="color: gray">粤ICP备2023102975号</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>

</template>

<style scoped>

</style>
