<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container">
      <!-- Brand -->
      <router-link class="navbar-brand" to="/">
        <img id="navbar-logo" alt="Image placeholder" src="@/img/logo.png">
      </router-link>
      <!-- Toggler -->
      <button aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-target="#navbarCollapse" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Collapse -->
      <div id="navbarCollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav mt-4 mt-lg-0 ml-auto">
          <li class="nav-item dropdown dropdown-animate " data-toggle="hover">
            <router-link class="nav-link" to="/SPDM" active-class="active">产品&解决方案</router-link>
            <div class="dropdown-menu dropdown-menu-single">
              <router-link class="dropdown-item" to="/">SPLM产品全生命周期管理平台</router-link>
              <router-link class="dropdown-item" to="/SPDM" active-class="active">SPDM产品数据管理系统</router-link>
              <router-link class="dropdown-item" to="/SPMS" active-class="active">SPMS科研项目管理系统</router-link>
              <router-link class="dropdown-item" to="/SMPM" active-class="active">SMPM结构化工艺管理系统</router-link>
              <router-link class="dropdown-item" to="/SCAXLink" active-class="active">SpeedDLink设计工具集成软件</router-link>
              <router-link class="dropdown-item" to="/S3DViewer" active-class="active">SpeedVue轻量可视化软件</router-link>
            </div>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" to="/case" active-class="active">技术服务</router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" to="/experience" active-class="active">体验中心</router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" to="/partners" active-class="active">合作伙伴</router-link>
          </li>
          <li class="nav-item ">
            <router-link class="nav-link" to="/about" active-class="active">关于我们</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import 'bootstrap';
export default {
  name: 'Navbar'
}
</script>
<style scoped>
.navbar-nav .nav-item .nav-link:hover,
.navbar-nav .nav-item .nav-link.active {
  color: rgb(1, 162, 128);
}
.navbar-nav .nav-item .nav-link {
  color: black;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active {
  color: rgb(1, 162, 128);
}
</style>
