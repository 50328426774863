import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import { plugin as vueTransitionsPlugin } from '@morev/vue-transitions';
// import '@morev/vue-transitions/dist/index.css';
import '@/assets/css/quick-website.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/icons'; // icon
import 'swiper/css/swiper.css';

// 引入 ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入其他插件，假设你使用的是 require 的方式
require(`@/plugins/particles`);
require(`@/plugins/axios`);

// import VueRouterTransition from 'vue-router-transition';
import Navbar from '@/views/components/Navbar.vue';
import Footer from '@/views/components/Footer.vue';
import Contact from '@/views/components/Contact.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

Vue.config.productionTip = false;

// 全局注册组件
Vue.component('Navbar', Navbar);
Vue.component('Footer', Footer);
Vue.component('Contact', Contact);
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);

// 创建 Vue 实例
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
