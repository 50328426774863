import Vue from 'vue';
import axios from 'axios';
import {Message} from 'element-ui'

/**
 * 创建ajax实例
 * 并设置超时时间
 */
const ajax = axios.create({
    timeout: 50000,
    baseURL:'http://127.0.0.1:18080'
})


ajax.interceptors.request.use((config) => {
    return config
// eslint-disable-next-line no-unused-vars
}, error => {
    // console.error('请求异常', error)
})

/**
 * 响应拦截器
 * 后端响应的信息
 */
ajax.interceptors.response.use((res) => {
    // console.log(res)
    if (res.status !==200) {
        Message.error(res.data);
        return false;
    }
    return res;

}, error => {
    let { message } = error
    if (message === 'Network Error') {
        Message.error("Network Error");
    } else if (error.response.status === 400) {
        Message.error(error.response.data.msg);
    }  else if (error.response.status === 404) {
        Message.error("后端接口未找到");
    } else if (error.response.status === 500) {
        Message.error("后端接口异常");
    } else {
        Message.error("未知错误");
    }
    return Promise.reject(error)
})

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        ajax.get(url, {params: params}).then(response => {
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

export function post(url, data = {}) {
    data = JSON.stringify(data)
    let config = {
        headers: {'Content-Type': 'application/json'}
    }
    return new Promise((resolve, reject) => {
        ajax.post(url, data, config).then(response => {
            resolve(response)
        }).catch(err => {
            reject(err)
        })
    })
}

// 挂载
Vue.prototype.$ajax = ajax
export default ajax
